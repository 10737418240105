exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-frame-index-tsx": () => import("./../../../src/pages/frame/index.tsx" /* webpackChunkName: "component---src-pages-frame-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-si-index-tsx": () => import("./../../../src/pages/si/index.tsx" /* webpackChunkName: "component---src-pages-si-index-tsx" */),
  "component---src-pages-si-policy-tsx": () => import("./../../../src/pages/si/policy.tsx" /* webpackChunkName: "component---src-pages-si-policy-tsx" */),
  "component---src-pages-ta-index-tsx": () => import("./../../../src/pages/ta/index.tsx" /* webpackChunkName: "component---src-pages-ta-index-tsx" */),
  "component---src-pages-ta-policy-tsx": () => import("./../../../src/pages/ta/policy.tsx" /* webpackChunkName: "component---src-pages-ta-policy-tsx" */)
}

